import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Pagination } from 'src/app/constants/api-response';
import { COLORS_CHART } from 'src/app/constants/chart-color';
import { forkJoin, Subject } from 'rxjs';
import { Pageable } from 'src/app/constants/pageable';
import { SearchKeywordsService } from 'src/app/services/search-keywords.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportService } from 'src/app/services/report.service';
import moment from 'moment';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PaginatorComponent } from '../../charts/paginator/paginator.component';
import { ModalService } from 'src/app/services/modal.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationService } from 'src/app/services/location.service';
import { DataPicker } from 'src/app/constants/data-picker';
@Component({
  selector: 'app-search-keywords',
  templateUrl: './search-keywords.component.html',
  styleUrls: ['./search-keywords.component.scss']
})
export class SearchKeywordsComponent implements OnInit {
  @ViewChild(PaginatorComponent, {static: false}) paginatorChild:PaginatorComponent;
  @Input() dataPicker: DataPicker;
  @Input() isReport: boolean = false;
  @Input() report;
  @Input() isShared = false;
  @Input() minDate = null;
  @Input() maxDate = null;

  public loading = true;
  public updatingData = false
  public gid: string;
  public reportId: string;
  public accountId: string;
  public locationId: string;
  public location: { accountId: string; locationId: string }[];
  public locations = [];
  public dataSourceMultiLoc = new MatTableDataSource([]);
  public selectDate: any = {};
  public searchKeywordsData: any = { graph: [], comparison: {}};
  public searchImpressionsData: any = {};
  public keywordsDatasource = new MatTableDataSource([]);
  public displayedColumnsKeywords = ['keywords', 'impressions'];
  public paginate: Pageable = {size: 25, page: 1};
  public pagination; paginationMultiloc: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public dataRange = { value: null, displayName: 'None'};
  public colors = COLORS_CHART;
  public labelsGraph = [];
  public sort = {
    sortBy: 'impressions',
    sortOrder: -1,
    sortDirection: 'desc'
  };
  public sortMultiloc = {
    sortBy: 'locationName',
    direction: 'asc'
  };
  public displayedColumnsMultLoc = ['location', 'Impressions', 'Keywords', 'Branded', 'Non-branded'];
  public fieldsColumnsMultiLoc = [
    { displayName:'locationName', fieldSort: 'locationName' },
    { displayName:'totalKeywords', fieldSort: 'totalKeywords' },
    { displayName:'more15Keywords', fieldSort: 'more15Keywords' },
    { displayName:'less15Keywords', fieldSort: 'less15Keywords' },
    { displayName:'estTotalimpressions', fieldSort: 'totalImpressionsLow' }
  ];
  public paginateMultiloc: Pageable = {size: 10, page: 1};
  public isComparisonVisible = false
  public keywordSearchInput = new FormControl();
  public locationIds = [];
  public keywordSubject = new Subject();
  public keywordCurrentValue: string;
  public isProgressCSV = false;
  public fieldsColumnsTopics = ['rank', 'details'];
  public dataSourceTopics = new MatTableDataSource([]);
  public topicsPaginate: Pageable = {size: 25, page: 1};
  public topicPagination  = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public tableLoading = true;
  public visibleGraph = 'total-impressions';
  public keywordsStats: any = {};
  public impressionsStats: any = {};
  public dataSet: any = [];

  constructor(
    public route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private SearchesKeywordsServ: SearchKeywordsService,
    public auth: AuthService,
    private reportS: ReportService,
    private modalS: ModalService,
    private locationS: LocationService
  ) {}

  ngOnInit() {
    if (!this.isReport) {
      this.accountId = this.route.parent.snapshot.params.accountId;
      this.locationId = this.route.parent.snapshot.params.locationId;
    } else {
      this.reportId = this.route.snapshot.params.id;
      if (this.report?.accounts?.length > 0) {
        this.locations = this.report?.accounts[0]?.locations;
      }
      this.dataRange = (this.report?.compareToValue && Object.keys(this.report?.compareToValue).length) ? this.report?.compareToValue : this.dataRange;
      this.report.showComparison = this.report.showComparison || false;
      if(this.report.showComparison) {
        this.isComparisonVisible = true;
        this.displayedColumnsKeywords = ['keywords', 'impressions', 'differencePercentage'];
        this.sort = {
          sortBy: this.dataRange?.value,
          sortOrder: -1,
          sortDirection: 'desc'
        }
      };
    }
    this.minDate = this.minDate?.startOf('month');
    this.maxDate = this.maxDate?.endOf('month');
    this.gid = this.auth.session.gid;
    this.location = [{ accountId: this.accountId, locationId: this.locationId }];
    this.selectDate = this.isReport ? { start: this.report.startDatetime, end: this.report.endDatetime } : this.locationS.buildDatepickerDate('keyword', this.maxDate);

    this.keywordSearchInput.valueChanges
    .subscribe(keyword => {
      this.keywordSubject.next(keyword?  keyword: '');
    })

    this.keywordSubject.pipe(
      debounceTime(650),
      switchMap( (keyword: string) => {

        if(this.keywordCurrentValue !== keyword){
          this.paginate.page = 1;
          this.keywordCurrentValue = keyword;
          this.paginatorChild.reset();
        }

        this.updatingData = true;
        const startDate = this.getDays().startDate;
        const endDate = this.getDays().endDate;

        if(keyword && !this.isReport){
          return this.SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, this.paginate, startDate, endDate,
            this.dataRange?.value, [this.locationId], this.sort)
        } 
        else if(keyword && this.isReport){
          return this.SearchesKeywordsServ.getSearchKeywordsAtlas(keyword, this.paginate, startDate, endDate,
            this.dataRange?.value, this.locationIds, this.sort)
        }
        else if (!keyword && this.isReport) {
          return this.SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, this.dataRange?.value, startDate, endDate, this.sort, this.paginate)
        }
        else if (!keyword && !this.isReport){
          return this.SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, this.dataRange?.value, startDate, endDate, this.sort, this.paginate)
        }
      }),
    )
     .subscribe(result => {
      this.updatingData = false;
        if(this.keywordSearchInput.value){
        this.keywordsDatasource = new MatTableDataSource(result?.data);
        this.pagination.per_page = this.paginate.size;
        this.pagination.page= this.paginate.page;
        this.pagination.hasNext= result?.hasNext;
        this.pagination.hasPrev= result?.hasPrev;
        this.pagination.items= result?.data;
        this.pagination.pages = Math.ceil(result?.total / this.pagination.per_page)
        this.pagination.total = result?.total;
      } else {
        this.keywordsDatasource = new MatTableDataSource(result?.items);
        this.pagination = {
          per_page: result?.per_page,
          page: result?.page,
          hasNext: result?.hasNext,
          hasPrev: result?.hasPrev,
          pages: result?.pages,
          total: result?.keywordsCount,
          items: result?.items
        }
      } 
    },
    err => {
      this.updatingData = false;
      this.keywordsDatasource = new MatTableDataSource([]);
    });

    if (this.isReport || this.isShared) {
      this.getReportData();
    } else  {
      this.getData();
    }
  }

  getDays() {
   return {
    startDate: this.selectDate?.start ? moment(this.selectDate?.start).format('YYYY-MM-DD') : null,
    endDate: this.selectDate?.end ? moment(this.selectDate?.end).format('YYYY-MM-DD') : null
   }
  }

  getReportData() : void {
    this.tableLoading = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    this.dataPicker.range = {
      start: startDate,
      end: endDate
    }
    this.dataSourceMultiLoc = new MatTableDataSource([]);
   
    forkJoin([
      this.SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, this.dataRange?.value, startDate, endDate, this.sort, this.paginate),
      this.reportS.getSearchKeywordsBrands(null, null, this.gid, this.reportId, startDate, endDate, this.dataRange?.value),
      this.reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc ,this.sortMultiloc, this.dataPicker),
      this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, null, null)
    ]).subscribe(
      result => {
        this.locationIds = result[0]?.locationIds;
        this.buildSearchData(result[0]);
        this.buildGraphs(result[1]);
        this.buildMultiLocTable(result[2]?.data);
        this.buildTopics(result[3]);
        this.visibleGraph = this.graphIsVisible ? this.visibleGraph : 'total-impressions';
        this.setGraphData();
        this.loading = false;
        this.updatingData = false;
        this.tableLoading = false;
        this.detectChanges();
      },
      err => {
        this.loading = false;
        this.updatingData = false;
        this.tableLoading = false;
        this.modalS.openConfirmModal(
          'Loading error',
          'There was an error while loading the data. Please try again or contact support (error code 4)',
        (() => {
          this.auth.signOut(true, true)
        }),
        AlertType.ERROR,
        'Retry');
      }
    )
  }

  getData() : void {
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;

    forkJoin([
      this.SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, this.dataRange?.value, startDate, endDate, this.sort, this.paginate),
      this.reportS.getSearchKeywordsBrands(this.accountId, this.locationId, this.gid, null, startDate, endDate, this.dataRange?.value),
      this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, null, this.locationId, this.accountId)
    ]).subscribe(
      async result => {
        const dateValidations = await this.locationS.getDateValidations('keyword', [this.accountId], [this.gid], [this.locationId]).toPromise();
        const dates = this.locationS.dateValidation(dateValidations);
        this.minDate = dates.minDate?.startOf('month');
        this.maxDate = dates.maxDate?.endOf('month');
        this.buildSearchData(result[0]);
        this.buildGraphs(result[1]);
        this.buildTopics(result[2]);
        this.visibleGraph = this.graphIsVisible ? this.visibleGraph : 'total-impressions';
        this.setGraphData();
        this.loading = false;
        this.updatingData = false;
        this.detectChanges();
      },
      err => {
        this.loading = false;
        this.updatingData = false;
        this.modalS.openConfirmModal(
          'Loading error',
          'There was an error while loading the data. Please try again or contact support (error code 3)',
        (() => {
          this.auth.signOut(true, true)
        }),
        AlertType.ERROR,
        'Retry');
      }
    )
  }

  getKeywordsTable() : void {
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    
    this.SearchesKeywordsServ.getSearchKeywords(this.gid, this.reportId, this.locationId, this.accountId, this.dataRange?.value, startDate, endDate, this.sort, this.paginate).subscribe(
        result => {
          this.buildSearchData(result);
          this.updatingData = false;
          this.detectChanges();
        },
        err => {
          this.updatingData = false;
        }
      )
  }

  buildGraphs(results): void {
    this.labelsGraph = results?.data?.labels;
    this.searchImpressionsData = results?.data?.comparison?.impressions;
    this.impressionsStats = results?.data?.impressions;

    this.searchKeywordsData.graph = results?.data?.comparison?.keywords;
    this.keywordsStats = results?.data?.keywords;
    
    this.setGraphData();
  }

  setGraphData(): void {
    this.dataSet = this.visibleGraph === 'total-impressions' ? this.impressionsStats : this.keywordsStats;
  }

  getMultilocTable(): void {
    this.updatingData = true;
    this.tableLoading = true;
    this.dataSourceMultiLoc = new MatTableDataSource([]);
    this.dataPicker.range = {
      start: this.getDays().startDate,
      end:this.getDays().endDate
    }

      this.reportS.getTableData(this.gid, 'keywords', this.paginateMultiloc ,this.sortMultiloc, this.dataPicker).subscribe(
      res => {
        this.buildMultiLocTable(res?.data);
        this.tableLoading = false;
        this.updatingData = false;
      },
      err => this.updatingData = false
    );
  }

  getTopicsTable() : void {
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;

    this.SearchesKeywordsServ.getTopicsData(startDate, endDate, this.topicsPaginate, this.gid, this.reportId, this.locationId, this.accountId).subscribe(
      res => {
        this.updatingData = false;
        this.buildTopics(res);
      },
      err => {
        this.updatingData = false;
    }
    )
  }

  buildSearchData(data) {
    this.searchKeywordsData.comparison = data?.comparison?.keywords; 
    this.keywordsDatasource = new MatTableDataSource(data?.items);
    this.pagination = {
      per_page: data?.per_page,
      page: data?.page, 
      hasNext: data?.hasNext,
      hasPrev: data?.hasPrev,
      pages: data?.pages,
      total: data?.comparison.keywords?.actual,
      items: data?.items
    };
  }

  buildCardsData(data, start, end) {
    this.selectDate = {
      start: moment(start),
      end: moment(end)
    }
  }


  buildMultiLocTable(data) {
    data.items.forEach(i => i.estTotalimpressions = `${new Intl.NumberFormat().format(i?.totalImpressionsLow || 0)} - ${new Intl.NumberFormat().format(i?.totalImpressionsHigh || 0)}`);
    this.dataSourceMultiLoc = new MatTableDataSource(data?.items);
    this.paginationMultiloc = {
      items: data['items'],
      page: data['page'],
      pages: data['totalPages'],
      per_page: data['pageSize'],
      total: data['total'],
      hasNext: data['hasNext'],
      hasPrev: data['hasPrev']
    };
  }

  buildTopics(data) {
    this.dataSourceTopics = new MatTableDataSource(data?.keyword_trends?.items);
    this.topicPagination = {
      per_page: data?.keyword_trends?.per_page,
      page: data?.keyword_trends?.page,
      hasNext: data?.keyword_trends?.hasNext,
      hasPrev: data?.keyword_trends?.hasPrev,
      pages: data?.keyword_trends?.pages,
      total: data?.keyword_trends?.total,
      items: data?.keyword_trends?.items
    }
  }

  topicsHandleReload(event) {
    this.updatingData = true;
    this.topicsPaginate = event;
    this.getTopicsTable();
  }

  async handleExportCsvTopic(item) {
    this.isProgressCSV = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    
    await this.SearchesKeywordsServ.handleExportCsvTopic(this.gid,  this.reportId, this.accountId, this.locationId, startDate, endDate, item?.topics);
    this.isProgressCSV = false;
  }

  async handleExportCsvKeywordsSearch(){
    this.isProgressCSV = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate
    let locations
    if (this.locationIds.length > 0){
      locations = this.locationIds
    } else {
      locations = [this.locationId]
    }
    await this.SearchesKeywordsServ.handleExportCsvKeywordsSearch(
      startDate, endDate, locations, this.keywordSearchInput.value , this.dataRange?.value, this.sort
      );
    this.isProgressCSV = false;
  }

  handleReload(event) {
    this.paginate = event;

    if(this.keywordSearchInput.value){
      this.keywordSearchInput.setValue(this.keywordSearchInput.value)
    } else {
      this.updatingData = true;
      if(this.isReport || this.isShared){
        this.getReportData();
      }
      else{
        this.getData();
      }
    }
  }

  sortChanged(event, onlyTable = false) {
    this.updatingData = true;
    this.sort = {
      sortBy: event.active === 'impressions' ? event.active : this.dataRange.value,
      sortOrder: event.direction === 'asc' ? 1 : -1,
      sortDirection: event.direction
    }
    
    this.paginate = {
      page: 1,
      size: this.paginate.size
    }
    
    if(this.keywordSearchInput.value){
      this.keywordSearchInput.setValue(this.keywordSearchInput.value)
    } else {
      this.updatingData = true;
      if(this.isReport || this.isShared){
        this.getReportData();
      }
      else{
        this.getData();
      }
    }
  }

  handleDataPicker(event) {
    this.updatingData = true;
    this.selectDate.start = event.range.start;
    this.selectDate.end = event.range.end;
    
    if(this.isReport || this.isShared){
      this.getReportData();
    }
    else{ // not report
      this.getData();
    }
  }

  handleSelectRange(event) {
    if (this.dataRange.value == event.value) { return; }
    this.dataRange = event;
    const sort = {
      active: this.isComparisonVisible ? 'differencePercentage' :'impressions', 
      direction: 'desc'
    }
    this.sortChanged(sort);
  }

  handleMultilocSort(event) {
    this.updatingData = true;
    this.paginateMultiloc = {size: this.paginateMultiloc.size, page: 1}
    this.sortMultiloc = {
      sortBy: event.active.replace('_', ''),
      direction: event.direction
    };
    this.getMultilocTable();
  }

  handleMultilocPaginate(event) {
    this.updatingData = true;
    this.paginateMultiloc = event;
    this.getMultilocTable();
  }

  async handleExport($event) {
    this.isProgressCSV = true;
    const startDate = this.getDays().startDate;
    const endDate = this.getDays().endDate;
    const period = !this.isComparisonVisible ? null : this.dataRange?.displayName?.split(' ')[1];

    await this.reportS.handleExportCsvMongo(this.reportId, this.gid, this.accountId, this.locationId, startDate, endDate, '', "keywords", period);

    this.isProgressCSV = false;
  }

  getImpressionsValue(numberOfImpressions) {
    return numberOfImpressions > 0 ? numberOfImpressions.toLocaleString("en-US") : '<15';
  }

  changedViewComparison(event) {
    this.isComparisonVisible = event;
    this.displayedColumnsKeywords = this.isComparisonVisible ? ['keywords', 'impressions', 'differencePercentage'] : ['keywords', 'impressions'];
    const sort = {
      active: this.isComparisonVisible ? 'differencePercentage' :'impressions', 
      direction: 'desc'
    }
  }

  getComparisonBoxClass(field, row?): string {
    let percentage;

    switch(field){
      case 'keywords':
        percentage = this.searchKeywordsData?.comparison?.trend;
        break;
      case 'impressions':
        percentage = this.searchImpressionsData?.trend?.total;
        break;
      case 'branded':
        percentage = this.searchImpressionsData?.trend?.direct;
        break;
      case 'non-branded':
        percentage = this.searchImpressionsData?.trend?.discovery;
        break;
      case 'keywords-table':
        percentage = row?.prevComparison?.percentage;
        break;
    }

    if (percentage > 0) {
      return 'chip--trend--success';
    } else if (percentage < 0) {
      return 'chip--trend--fail';
    } else {
      return 'chip--trend--info';
    }
  }

  changedVisibleGrapg(event): void {
    this.visibleGraph = event.value;
    this.setGraphData();
  }

  detectChanges(): void{
    if ( this.cdRef !== null && this.cdRef !== undefined && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  getChipValue(value) {
    return (
      value === 0 ? '-' : 
      value > 1000 ? 1000 : 
      value
    );
  }

  get graphIsVisible(): boolean {
    const start = typeof(this.selectDate.start) === 'string' ? moment(this.selectDate.start) : this.selectDate.start;
    const end = typeof(this.selectDate.end) === 'string' ? moment(this.selectDate.end) : this.selectDate.end;
    const diff = end.diff(start, 'months');
    return diff !== 0
  }

  get currentVisibleGraph(): string {
    return this.visibleGraph.replace('-', ' ');
  }
}
